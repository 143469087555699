import UrlHandlerApiService from "./url-handler.service";
import Vue from "vue";

const TagsService = {
  URL: "",
  async init() {
    this.URL = await UrlHandlerApiService.getBaseUrl();
  },
  API_TAGS: `api/shop/tags`,
  API_TAG_DELETE: id => `api/shop/tags/${id}`,
  API_TAG_ACTIVE: id => `api/shop/tags/${id}/active`,
  API_TAG_MERGE: (id, otherTagId) => `api/shop/tags/${id}/merge?otherTag=${otherTagId}`,

  getTags(params) {
    try {
      return Vue.axios.get(this.URL + this.API_TAGS, { params });
    } catch (error) {
      this.$log.error("Error: ", error);
      throw new Error(`[KT] TagsService ${error}`);
    }
  },

  setTagActiveState(tagId) {
    try {
      return Vue.axios.put(this.URL + this.API_TAG_ACTIVE(tagId));
    } catch (error) {
      this.$log.error("Error: ", error);
      throw new Error(`[KT] TagsService ${error}`);
    }
  },

  createTag(body) {
    try {
      return Vue.axios.post(this.URL + this.API_TAGS, body);
    } catch (error) {
      this.$log.error("Error: ", error);
      throw new Error(`[KT] TagsService ${error}`);
    }
  },

  updateTag(body, id) {
    try {
      return Vue.axios.post(this.URL + this.API_TAGS + `/${id}`, body);
    } catch (error) {
      this.$log.error("Error: ", error);
      throw new Error(`[KT] TagsService ${error}`);
    }
  },

  async deleteTag(id) {
    try {
      return Vue.axios.delete(this.URL + this.API_TAG_DELETE(id));
    } catch (error) {
      this.$log.error("Error: ", error);
      throw new Error(`[KT] TagsService ${error}`);
    }
  },

  mergeTags(id, otherTagId) {
    try {
      return Vue.axios.put(this.URL + this.API_TAG_MERGE(id, otherTagId));
    } catch (error) {
      this.$log.error("Error: ", error);
      throw new Error(`[KT] TagsService ${error}`);
    }
  }
};

export default TagsService;
