// USA
import en from "vuetify/es5/locale/en";

export const locale = {
  current: "en",
  common: {
    all: "All",
    yes: "Yes",
    no: "No",
    requiredField: "Field is required",
    close: "Close",
    confirm: "Confirm",
    created: "Created",
    create: "Create",
    edit: "Edit",
    add: "Add",
    validTo: "Valid To",
    delete: "Delete",
    submit: "Submit",
    cancel: "Cancel",
    send: "Send",
    saveChanges: "Save changes",
    save: "Save",
    createdOn: "Created on:",
    description: "Description",
    descriptionMe: "Description (MNE)",
    descriptionEn: "Description (ENG)",
    descriptionDe: "Description (GER)",
    infoMe: "Info (MNE)",
    infoEn: "Info (ENG)",
    infoDe: "Info (GER)",
    price: "Price",
    error: "Error",
    active: "Active",
    inStock: "In stock",
    restrictions: "Restrictions",
    name: "Name",
    Name: "Name",
    nameEn: "Name (ENG)",
    nameMe: "Name (MNE)",
    nameDe: "Name (GER)",
    quantity: "Quantity",
    oldPrice: "Old Price",
    discount: "Discount (%)",
    priceDiscount: "Price with discount",
    finalPrice: "Final Price",
    shopId: "Shop ID",
    title: "Title",
    titleEn: "Title (English)",
    titleMe: "Title (Montenegrin)",
    titleDe: "Title (German)",
    text: "Text",
    logo: "Logo",
    activated: "Activated",
    deactivated: "Deactivated",
    enabled: "Enabled",
    disabled: "Disabled",
    email: "Email",
    user: "User",
    link: "Link",
    thumbnail: "Thumbnail",
    type: "Type",
    userType: "User type",
    shop: "Shop",
    image: "Image",
    start: "Start",
    end: "End",
    finish: "Finish",
    showBeforeStart: "Show Before Start",
    showBeforeStartMinutes: "Show Before Start (minutes)",
    donationGoal: "Donation Goal",
    itemLimit: "Item limit",
    occupancy: "Occupancy",
    inReviewItems: "Items In Review",
    sessionDurationSeconds: "Session Duration (seconds)",
    max: "Max",
    min: "Min",
    activeEnabled: "Active: Enabled",
    activeDisabled: "Active: Disabled",
    suspended: "Suspended",
    notSuspended: "Not suspended",
    shops: "Shops",
    globalGoals: "Global goals",
    externalId: "External Id",
    upsellLevel: "Upsell level",
    installmentDuration: "Installment duration",
    oobleeCoins: "Ooblee coins",
    advertisementOobleeCoins: "Advert. ooblee coins",
    fee: "Fee (%)",
    minNumberOfInstallments: "Min number of installments",
    numberOfFreeMonths: "Number of free months",
    permissions: "Permissions",
    approve: "Approve",
    reject: "Reject",
    mainFeedBanner: "Main feed banner",
    beforeEventBanner: "Before event banner",
    duringEventBanner: "During event banner",
    deny: "Deny",
    returnToReview: "Return to review",
    ordinalNumber: "Ordinal number",
    language: "Language",
    titleFirstSection: "First section title",
    titleSecondSection: "Second section title",
    contentFirstSection: "First section content",
    contentSecondSection: "Second section content",
    titleBusinessNote: "Business note title",
    code: "Code",
    claimed: "Claimed",
    expired: "Expired",
    used: "Used",
    users: "Users",
    shopAdmin: "Shop admin",
    numberOfCodes: "Number of codes",
    city: "City",
    startDate: "Start date",
    endDate: "End date",
    regStartDate: "Registration start",
    regEndDate: "Registration end",
    freeItem: "Free item",
    shoppingItems: "Shopping items",
    items: "Items",
    shoppingGroups: "Shopping groups",
    canInitiateNewGroup: "Can initiate new group",
    orderOptions: "Order options",
    isCurrentUserInitiator: "Is current user initiator",
    isCurrentUserJoiner: "Is current user joiner",
    maxNumberOfGroups: "Max number of groups",
    maxNumberOfMembers: "Max number of members",
    members: "Members",
    membersCount: "Members count",
    discountPercentage: "Discount percentage",
    oobleeDiscountPercentage: "Ooblee discount percentage",
    oobleeDiscountAmount: "Ooblee discount amount",
    additionalVoucherAmount: "Voucher amount",
    voucher: "Voucher",
    shopDiscount: "Shop discount",
    oobleeDiscount: "Ooblee discount",
    total: "Total",
    savedSuccessfully: "Saved successfully",
    itemCount: "Item Count",
    item: "Item",
    totalDiscount: "Total discount",
    discountFromShop: "Discount from shop",
    discountFromOoblee: "Discount from ooblee",
    programId: "Program ID",
    programName: "Program Name",
    copy: "Copy",
    validFrom: "Valid from",
    validUntil: "Valid until",
    createdAt: "Created at",
    optionalFilters: "Optional filters",
    content: "Content",
    areYouSure: "Are you sure",
    selectMultipleImages: "Select multiple images",
    addTagOnEnter: "Add tag on enter",
    availability: "Availability",
    color: "Color",
    size: "Size",
    restrictionType: "Restriction Type",
    people: "People",
    date: "Date",
    startTime: "Start Time",
    endTime: "End Time",
    newCampaign: "New Campaign",
    selectAll: "Select All",
    view: "View",
    newLimitation: "New Limitation",
    copyLimitation: "Copy Limitation",
    editLimitation: "Edit Limitation",
    editCampaign: "Edit Campaign",
    copyCampaign: "Copy Campaign",
    timePeriod: "Time Period",
    limitPerShop: "Limit Per Shop",
    limitForCampaign: "Limit for Campaign",
    listOfShops: "List of Shops",
    listOfVouchers: "List of Vouchers",
    sumPerShop: "Sum per shop",
    addShopToList: "Add Shop To List",
    nameOfLimit: "Name of Limit",
    nameOfShop: "Name of Shop",
    shopsChosen: "Shops chosen",
    inAction: "In Action",
    reachedTheLimit: "Reached The Limit",
    activeLimitsSum: "Active Limits Sum",
    leftLimitsSum: "Left Limits Sum",
    spentLastMonth: "Spent Last Month",
    limitSet: "Limit Set",
    spent: "Spent",
    left: "Left",
    archive: "Archive",
    campaigns: "Campaigns",
    spentLastYear: "Spent Last Year",
    sum: "Sum",
    addNewVariant: "Add new variant",
    startDateFuture: "Start date can't be in the future",
    atLeastOneItem: "Must contain at least one item",
    amount: "Amount",
    editGiftCardVoucher: "Edit gift card voucher",
    newGiftCardVoucher: "New gift card voucher",
    startFrom: "Starts from",
    endsat: "Ends at",
    products: "Products",
    cities: "Cities",
    grossTotal: "Gross Total",
    transactions: "Transactions",
    deepLink: "Deep Link",
    order: "Range/Order",
    search: "Search",
    sortBy: "Sort By",
    ascDesc: "Asc/Desc"
  },
  month: {
    jan: "January",
    feb: "February",
    mar: "March",
    apr: "April",
    may: "May",
    jun: "June",
    jul: "July",
    aug: "August",
    sep: "September",
    oct: "October",
    nov: "November",
    dec: "December"
  },
  $vuetify: {
    ...en
  },
  menu: {
    dashboard: "Dashboard",
    users: "Users",
    shops: "Shops",
    shopPosts: "Shop Posts",
    socialPosts: "Social Posts",
    shoppingEvents: "Shopping Events",
    shoppingEventPosts: "Shopping Event Posts",
    donationGoals: "Donation Goals",
    globalGoals: "Global Goals",
    banners: "Banners",
    messages: "Messages",
    broadcast: "Broadcast",
    groups: "Groups",
    shopAdvertisedPosts: "Shop Advertised Posts",
    orders: "Orders",
    categories: "Categories",
    shopCategories: "Shop Categories",
    oobleeCategories: "Ooblee Categories",
    recommendedCategories: "Recommended",
    mainFeedCategories: "Main Feed Categories",
    mainPageSectors: "Main Page Sectors",
    sponsoredBy: "Sponsored By",
    eventsAndNews: "Events & News",
    suggestedForYou: "Suggested for you",
    recommendedBy: "Recommended by",
    promotions: "Promotions",
    titleDefinitions: "Title Definitions",
    promotionInstances: "Promotion instances",
    bannerDefinitions: "Banner definitions",
    bannerInstances: "Banner instances",
    shopsInformation: "Shops Information",
    walletApproval: "Wallet Approval",
    packages: "Packages",
    packagePermissions: "Package Permissions",
    broadcastMessages: "Broadcast Messages",
    newCollection: "New Collection",
    memberships: "Memberships",
    shopProductImporter: "Product Importer",
    apUsers: "AP Users",
    superMemberships: "Super Memberships",
    legal: "Legal",
    vouchers: "Voucher",
    voucherList: "Voucher List",
    voucherLimitations: "Voucher Limitations",
    promoCodes: "Promo Codes",
    groupShopping: "Group Shopping",
    algorithm: "Algorithm",
    swfOffer: "SWF Offer",
    swf: "Shopping with friends",
    swfConfiguration: "Configuration",
    referral: "Customer Referral",
    referralProgram: "Programs",
    referralInvitation: "Initiators and Referrals ",
    scheduler: "Scheduler",
    influencers: "Influencers",
    allUsers: "All users",
    newPost: "Add new post",
    coins: "Coins",
    campaigns: "Campaigns",
    welcomeScreen: "Welcome screen",
    advertising: "Advertising",
    wheel: "Wheel of fortune",
    giftCard: "Gift card voucher",
    treatCampaign: "Treat campaign",
    generalSettings: "General settings",
    payments: "Payments",
    tagCenter: "Tag center",
    tags: "Tags",
    districts: "Districts"
  },
  commonTable: {
    email: "email",
    date: "Date",
    userName: "User Name",
    user: "User",
    category: "Category",
    categories: "Categories",
    shoppingEventPosts: "Shopping Event Posts",
    total: "Total",
    published: "Published",
    city: "City",
    country: "Country",
    cities: "Cities",
    shops: "Shops",
    shopName: "Shop Name",
    shopId: "Shop ID",
    productName: "Product Name",
    productPicture: "Product Picture",
    productDescription: "Product Description",
    productPrice: "Product Price",
    productDiscount: "Product Discount",
    discountInPercentage: "Discount %",
    discountInCurrency: "Discount €",
    oobleeDiscountInCurrency: "Ooblee Discount €",
    status: "Status",
    product: "Product",
    price: "Price",
    orderType: "Order Type",
    paid: "Paid",
    finalPrice: "Final Price",
    priceBeforeDiscount: "Price Before Discount",
    oldPrice: "Old Price",
    adminStatus: "Status (Admin)",
    shopStatus: "Status (Shop)",
    quantity: "Quantity",
    shoppingEvent: "Shopping Event",
    shopProduct: "Shop Product",
    donationAmount: "Donation Amount",
    promoStart: "Promo Start",
    promoFinish: "Promo Finish",
    userRegStart: "Users registration start from",
    userRegFinish: "Users registration finish on",
    numOfRef: "Number of new referral users",
    voucherValue: "Voucher value",
    voucherVariants: "Voucher variants",
    numOfVouchers: "Number of vouchers",
    offerTime: "Days to fulfill offer",
    name: "Name",
    startFrom: "Start From",
    finished: "Finished",
    validFrom: "Valid From",
    validUntil: "Valid Until",
    type: "Type",
    voucherPrice: "Voucher(used amount)"
  },
  pages: {
    importCsv: {
      sendRequestMessage: "Do you want to send a new product import request"
    },
    shops: {
      entityName: "Name",
      email: "email",
      phoneNumber: "Phone Number",
      date: "Date",
      cityCountry: "City (Country)",
      activated: "Activated",
      groupShopping: "Group Shopping",
      suggestedShopping: "Suggested Shopping",
      redirectUrl: "Redirect URL",
      donations: "Donations",
      personalSpecialOffer: "Personal Special Offer",
      banned: "Banned",
      cityShopping: "City Shopping",
      subscriptions: "Subscriptions",
      gift: "Gift",
      shop: "Shop",
      tags: "tags",
      promotions: "promotions",
      edit: "Edit",
      language: "language",
      shops: "shops",
      vatNumber: "VAT number",
      taxId: "Tax Id",
      activeShopsWithPostCount: "Active Shops with post count"
    },
    shopPosts: {
      shopName: "Shop Name",
      date: "Date",
      productPicture: "Product Picture",
      productName: "Product Name",
      gender: "Gender",
      productDescription: "Product Description",
      productDiscount: "Product Discount",
      productPrice: "Product Price",
      city: "City",
      category: "Category",
      published: "Published",
      editors: "Editors",
      product: "Product",
      categoryPicker: "Category Picker",
      tags: "Tags",
      delete: "Delete",
      edit: "Edit",
      catPickerFor: "Category Picker for",
      shopPostTags: "Shop post tags",
      areYouSureDelete: "Are you sure you want to delete post ",
      fromEvents: " from events ?",
      detailsForShopPost: "Details for shop post "
    },
    socialPosts: {
      userProfileId: "User profile ID",
      postPicture: "Post Picture",
      postDescription: "Post Description",
      published: "Published",
      post: "Post",
      postCreator: "Post creator",
      content: "Content",
      likeCount: "Like Count",
      commentCount: "Comment count",
      sharedPostType: "Shared Post Type"
    },
    banners: {
      banner: "Banner",
      bannerId: "Banner ID",
      bannerPicture: "Banner picture",
      bannerText: "Banner text",
      bannerBeforeEvent: "Banner before event",
      bannerDuringEvent: "Banner during event"
    },
    advertisedPosts: {
      apr: "Advertised Posts Report",
      tr: "Total Report"
    },
    orders: {
      orderId: "Order ID",
      orderNumber: "Order #",
      customerName: "Customer Name",
      customerEmail: "Customer Email",
      colorSize: "Color/Size",
      orderOption: "Order Option",
      location: "Location",
      totalPrice: "Total Price",
      disputeMessage: "Dispute Message",
      orderInfo: "Order Info",
      or: "Izvještaj o porudžbinama",
      oir: "Izvještaj o poručenim proizvodima",
      locationReservation: "LocationReservation",
      customerAddress: "Customer Address",
      customerPhone: "Customer Phone",
      customerInfo: "Customer Info",
      startDate: "Start Date",
      endDate: "End Date"
    },
    recommendedCategories: {
      insertCategory: "Add Category",
      allCities: "All Cities",
      deleteCatMessage: "Are you sure you want to delete category with id = ",
      allEnabled: "All Cities: Enabled",
      allDisabled: "All Cities: Disabled"
    },
    shopCategories: {
      order: "Order",
      name: "Name",
      categoryType: "Category Type",
      advertRelevant: "Advert Relevant",
      createPostRelevant: "Create Post Relevant",
      iconNormalPNG: "Icon normal (PNG)",
      iconActivePNG: "Icon active (PNG)",
      iconNormalSVG: "Icon normal (SVG)",
      iconActiveSVG: "Icon active (SVG)",
      deleteMessage: "Are you sure you want to delete category ",
      detailsTitle: "Shop category details",
      insertTitle: "Insert new shop category",
      published: "Published"
    },
    mfCategories: {
      order: "Order",
      name: "Name of category",
      image: "Image",
      city: "City",
      active: "Active",
      type: "Type"
    },
    sectors: {
      sectorName: "Sector Name",
      shops: "Shops",
      type: "Type",
      active: "Active",
      deleteSectorMessage: "Are you sure you want to delete sector with id = ",
      donationBanner: "Donation Banner"
    },
    sponsoredBy: {
      deleteMessage: "Are you sure you want to delete sponsor with id = ",
      sponsoredBy: "Sponsored By",
      sponsor: "Sponsor"
    },
    suggestedForYou: {
      editSuggested: "Suggested for you details",
      insertSuggested: "Umetni novo predloženo za tebe",
      deleteMessage: "Are you sure you want to delete suggested for you with id = "
    },
    newCollection: {
      editNewCollection: "New collection details",
      insertNewCollection: "Insert new collection",
      deleteMessage: "Are you sure you want to delete new collection with id = "
    },
    shoppingEvents: {
      donationsUrl: "Donation url",
      detailsUrl: "Details url",
      globalGoal: "Global goal",
      totalAmountCollected: "Total amount collected",
      donationGoalDeleteMessage: "Are you sure you want to delete donation goal with id = ",
      globalGoalDeleteMessage: "Are you sure you want to delete global goal with id = ",
      shoppingEventDeleteMessage: "Are you sure you want to delete shopping event with id = "
    },
    news: {
      deleteMessage: "Are you sure you want to delete news with id = "
    },
    recommendedBy: {
      deleteMessage: "Are you sure you want to delete recommended by with id = "
    },
    titleDefinition: {
      deleteMessage: "Are you sure you want to delete title definition with id = "
    },
    promotions: {
      deleteMessage: "Are you sure you want to delete promotion with id = ",
      addNew: "Add New Shop"
    },
    bannerDefinitions: {
      linkType: "Link Type",
      bannerType: "Banner Type",
      deleteMessage: "Are you sure you want to delete banner definition with id = ",
      instanceDelete: "Are you sure you want to delete banner instance with id = "
    },
    broadcastMessages: {
      subject: "Subject",
      body: "Body",
      schedule: "Schedule",
      sent: "Sent",
      forShopUsers: "For shop users",
      deleteMessage: "Are you sure you want to delete broadcast message with id = ",
      forShopUsersEnabled: "For shop users: Enabled",
      forShopUsersDisabled: "For shop users: Disabled"
    },
    membership: {
      shopId: "Shop ID",
      shopName: "Shop Name",
      commission: "Commission",
      validTo: "Valid To",
      package: "Package",
      voucher: "Voucher",
      voucherEuro: "Voucher (€)",
      reason: "Reason",
      subscriptionId: "Subscription ID",
      subscriptionName: "Subscription Name",
      subscriptionType: "Subscription Type",
      subscriptionTimeInterval: "Subscription Interval",
      autoRenewal: "Auto Renewal",
      lastPaymentDate: "Last Payment Date",
      invoiceUrl: "Invoice Url",
      invoiceStatus: "Invoice Status",
      invoice: "Invoice",
      redeem: "Redeem",
      haveRedeems: "Have redeems",
      membershipId: "Membership ID",
      walletAmount: "WalletAmount",
      iban: "IBAN",
      bic: "BIC",
      status: "Status",
      approveMessage: "Are you sure you want to approve ",
      rejectMessage: "Are you sure you want to reject "
    },
    apUsers: {
      activationDate: "Activation Date",
      superAdmin: "Super Admin",
      cityRoles: "City Roles",
      fullName: "Full Name",
      password: "Password",
      repeatPassword: "Repeat Password"
    },
    users: {
      influencer: "Influencer",
      gender: "Gender",
      activationDate: "Activation Date",
      profile: "Profile",
      suspended: "Suspended"
    },
    influencers: {
      influencer: "Influencer",
      gender: "Gender",
      requestedDate: "Requested Date",
      profile: "Profile",
      suspended: "Suspended",
      approveInfluencer: "Approve as Influencer",
      sendEmail: "Send email"
    },
    scheduler: {
      startDateAndTime: "Start Date and Time",
      endDateAndTime: "End Date and Time",
      postCount: "Post Count",
      plannedCost: "Planned Cost",
      publishOnScheduler: "Publish on Scheduler"
    }
  },
  lang: {
    me: "Montenegrin",
    en: "English",
    de: "German"
  },
  gender: {
    male: "Male",
    female: "Female",
    unisex: "Unisex",
    all: "All"
  },
  influencerStatuses: {
    requestSent: "Requested",
    emailSent: "Email sent",
    underReview: "Under review",
    active: "Approved",
    rejected: "Rejected"
  },
  date: {
    from: "From",
    to: "To"
  },
  shopUpdate: {
    taxId: "Tax Id Number",
    bankAccount: "Bank Account Number",
    submit: "Submit",
    cancel: "Cancel",
    clearance: "Clearance level",
    details: "Details for shop ",
    lvl1: "1st level clearance",
    lvl2: "2nd level clearance",
    lvl3: "3nd level clearance",
    zipCode: "Zip Code"
  },
  swGift: {
    gifts: "Gifts",
    gValue: "Gift value",
    gValid: "Valid To",
    addNew: "Add New Gift",
    value: "Value",
    used: "Used",
    giftsForShop: "Gifts for shop "
  },
  scs: {
    add: "Add New Shopping City",
    city: "City"
  },
  voucher: {
    addUser: "Add user to voucher",
    addShop: "Add shop to voucher",
    campaignWarning:
      "Campaigns cannot have recurring shops in the same timeframe. Please choose a different timeframe for recurring shops."
  },
  promoCode: {
    addShop: "Add promo code to voucher"
  },
  promoSub: {
    promoSub: "Promotion subscriptions",
    ood: "Offer of the day",
    so: "Special offer",
    se: "Shopping event",
    subFor: "Subscriptions for shop "
  },
  welcomeScreen: {
    addNewSettingsFor: "Add new city settings for ",
    addNewSettings: "Add new settings",
    editSettings: "Edit settings",
    addNewModification: "Add new modification",
    fieldPlacement: "Field placemenet example",
    viewInfo: "View additional setting info",
    additionalInfo: "Additional setting info",
    BOTTOM_PARAGRAPH: "Bottom paragraph",
    WELCOME_PARAGRAPH: "Welcome paragraph",
    TITLE: "Title",
    LEGAL_TEXT: "Legal text",
    LEGAL_TITLE: "Legal title",
    HEADER_IMAGE: "Header image",
    FOOTER_IMAGE: "Footer image",
    DEFAULT_CITY_SETTINGS: "City default settings",
    CITY_SETTINGS: "City settings",
    DEFAULT_SETTINGS: "Default settings",
    timeInfo:
      "The displayed time will be converted to UTC time. The message might be sent within 1 hour of the scheduled time.",
    campaignInfo:
      "Between the end of the previous campaign and the beginning of the next one, fixed values for the given fields will be displayed.",
    TOP_PARAGRAPH: "Top paragraph",
    MAIN_TITLE: "Main title",
    BUTTON_TEXT: "Button text",
    BELOW_BUTTON_TEXT: "Below button text"
  },
  coins: {
    enums: {
      Affiliate: "Affiliate",
      LoyaltyClubMember: "Loyalty Club Member",
      Regular: "Regular",
      RegularShopper: "Regular Shopper",
      VipClubMember: "Vip Club Member"
    },
    coins: "coins",
    generalRules: "General Rules",
    voucherExchangeRule: "Voucher exchange rule",
    defaultForAllPlaces: "Default for all places",
    generalSettings: "General Settings",
    coinWillHaveValue: "Coin will have value",
    exchangeConditions: "Exchange Conditions",
    whenUserHas: "When user has",
    recieveVoucher: "user will automatically receive voucher",
    explanationPopup: "Explanation pop-up",
    campaignAndSpecialOffers: "Campaigns and special offers",
    newCampaign: "New campaign",
    campaignName: "Campaign Name",
    promoStart: "Promo Start",
    promoFinish: "Promo Finish",
    archive: "Archive"
  }
};
