import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import TagsService from "@/core/services/tags.service";
import ApiService from "@/core/services/api.service";
import ConfirmDialog from "@/view/pages/ConfirmDialog";

export default {
  name: "tags",
  components: {
    ConfirmDialog
  },
  computed: {
    name() {
      return this.$i18n.t("menu.tags");
    },
    headers() {
      return [
        {
          text: "Id",
          align: "start",
          sortable: false,
          value: "id",
          width: "2%"
        },
        {
          text: this.$i18n.t("common.name"),
          value: "caption",
          sortable: true
        },
        {
          text: this.$i18n.t("common.active"),
          value: "active",
          sortable: false,
          align: "left"
        },
        {
          text: this.$i18n.t("common.order"),
          value: "ord",
          align: "false",
          sortable: true
        },
        {
          text: this.$i18n.t("common.shops"),
          value: "shopIds",
          align: "left",
          sortable: true
        },
        {
          text: this.$i18n.t("commonTable.country"),
          value: "country",
          sortable: false,
          align: "left"
        },
        {
          actions: "Actions",
          value: "actions",
          aling: "center",
          width: "2%",
          sortable: false
        }
      ];
    }
  },
  data() {
    return {
      tags: [],
      originalTags: [],
      totalTags: 0,
      tagsApiInitiated: false,
      loading: true,
      pagination: {
        page: 1,
        itemsPerPage: 10
      },
      showSidebar: false,
      editTagId: null,
      form: this.createForm(),
      valid: false,
      shops: [],
      shopsAreLoading: false,
      searchShops: "",
      selectedShops: null,
      showDialog: false,
      merge: {
        selectedTag: {
          caption: "",
          id: null
        },
        tagId: null,
        otherTag: {
          caption: "",
          id: null
        }
      },
      searchTxt: ""
    };
  },
  watch: {
    pagination: {
      handler() {
        if (this.tagsApiInitiated) this.getTags();
      },
      deep: true
    }
  },
  async created() {
    this.loading = true;
  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.name }]);
    this.$log.debug("await init tags service - start");
    await TagsService.init();
    this.$log.debug("await init tags service - end");
    this.tagsApiInitiated = true;

    this.getShopList();
    this.getTags();
  },
  methods: {
    createForm() {
      return {
        caption: "",
        ord: null,
        shopIds: []
      };
    },
    setForm(tag) {
      this.selectedShops = this.findSelectedShops(tag.shopIds);
      return {
        caption: tag.caption,
        ord: tag.ord,
        shopIds: tag.shopIds
      };
    },
    findSelectedShops(shopIds) {
      if (!shopIds?.length) return [];
      return this.shops.filter(shop => {
        if (shopIds.includes(shop.id)) return shop;
      });
    },
    resetForm() {
      this.selectedShops = [];

      this.form = {
        caption: "",
        ord: null,
        shopIds: []
      };
    },
    handleSortByLogic() {
      const sortMapping = {
        ord: "order",
        shopIds: "shops"
      };

      let sort;
      let sortBy = this.pagination.sortBy;
      let sortDesc = this.pagination.sortDesc;
      if (sortBy.length === 1 && sortDesc.length === 1) {
        sort = sortMapping[sortBy[0]] || sortBy[0];
        if (sortDesc[0] === true) sort += "_DESC";
      }

      return sort;
    },
    getRequestParams() {
      const params = {};
      const sort = this.handleSortByLogic();

      params["sort"] = sort?.toUpperCase();
      params["page"] = this.pagination.page - 1;
      params["size"] = this.pagination.itemsPerPage;
      params["showAll"] = true;
      params["search"] = this.searchTxt;
      return params;
    },
    async getTags() {
      const reqParams = this.getRequestParams();
      const response = await TagsService.getTags(reqParams);
      this.tags = this.handleTagsTableData(response);
      this.originalTags = this.handleTagsTableData(response);
      this.totalTags = response.data.totalElements;
      this.$store.dispatch(SET_BREADCRUMB, [{ title: `${this.name}: ` + this.totalTags }]);
      this.loading = false;
    },
    handleTagsTableData(response) {
      if (!response?.data) return [];
      return (
        response.data.content.map(item => ({
          ...item,
          country: "Austria",
          totalShops: item.shopIds?.length || 0
        })) || []
      );
    },
    getShopList() {
      ApiService.query("api/shops/dropdown-list")
        .then(res => {
          this.shops = res.data;
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.shopsAreLoading = false));
    },
    selectAllShops(event) {
      this.shopsToAdd = event ? this.shops : [];
    },
    onSearch() {
      this.loading = true;
      this.getTags();
    },
    onEditTag(tag) {
      this.showSidebar = true;
      this.editTagId = tag.id;
      this.form = this.setForm(tag);
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    onOpenMergeDialog(tag) {
      this.merge.selectedTag = tag;
      this.showDialog = true;
    },
    mergeTags() {
      if (!this.merge?.tagId) return;
      if (this.merge.selectedTag.id === +this.merge.tagId) {
        this.$bvToast.toast("Cannot merge same tag!", {
          title: "Warning",
          solid: true,
          variant: "warning",
          autoHideDelay: 1000
        });
      }

      this.loading = true;
      TagsService.mergeTags(this.merge.selectedTag.id, this.merge.tagId).then(() => {
        this.successToastrMessage();
        this.getTags();
      });
      this.showDialog = false;
    },
    onChangeTagId() {
      if (!this.merge.tagId) return;
      if (this.merge.selectedTag.id === +this.merge?.tagId) return;
      const itemFound = this.tags.find(item => item.id === +this.merge.tagId);
      this.merge.otherTag = JSON.parse(JSON.stringify(itemFound));
    },
    removeOtherTag() {
      this.merge.otherTag.caption = "";
      this.merge.otherTag.id = null;
      this.merge.tagId = null;
    },
    onToggleTagActive(tag) {
      this.loading = true;
      TagsService.setTagActiveState(tag.id)
        .then(res => {
          console.log("setTagActiveState", res);
        })
        .finally(() => (this.loading = false));
    },
    async onDeleteTag(tag) {
      const confirm = await this.$refs.confirm.open(
        this.$i18n.t("common.confirm"),
        this.$i18n.t("common.areYouSure") + "?"
      );
      if (!confirm) return;
      TagsService.deleteTag(tag.id).then(() => {
        this.successToastrMessage();
        this.getTags();
      });
    },
    async save() {
      if (!this.$refs.form.validate()) return;

      const postModel = {
        ...this.form,
        shopIds: this.selectedShops.map(item => item.id)
      };

      try {
        const request = this.editTagId
          ? TagsService.updateTag(postModel, this.editTagId)
          : TagsService.createTag(postModel);

        this.loading = true;
        await request.finally(() => (this.loading = false));

        this.getTags();
        this.closeSidebar();
        this.this.successToastrMessage();
      } catch (error) {
        this.$bvToast.toast("Error: " + error, {
          title: this.$i18n.t("menu.messages"),
          solid: true,
          variant: "error",
          autoHideDelay: 2000
        });
      }
    },
    openSidebar() {
      this.showSidebar = true;
    },
    closeSidebar() {
      this.showSidebar = false;
      this.editTagId = null;
      this.resetForm();
    },
    successToastrMessage() {
      this.$bvToast.toast(this.$i18n.t("common.savedSuccessfully"), {
        title: this.$i18n.t("menu.tags"),
        solid: true,
        variant: "success",
        autoHideDelay: 1000
      });
    }
  }
};
